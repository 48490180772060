html,
body {
    padding: 0;
    margin: 0;
    color: #14343E;
    hyphens: auto;
}

a {
    color: inherit;
    text-decoration: none;
}

.szh-menu__item:has(a) {
    padding: 0;
}

.szh-menu__item--anchor,
.szh-menu__item a {
    display: block;
    padding: 0.375rem 1.5rem;
    flex: 1;
}

* {
    box-sizing: border-box;
}

:root {
    --color-background-green: #65bc7d;
}

.__green {
    color: #65bc7b;
}

.__bold {
    font-weight: 800;
}

/*
@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
}
 */

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.__i {
    font-style: italic;
}
