@use "styles/base";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
  padding: 20px 20px 0;
  height: 100px;

  @include base.responsive(1200px) {
    padding: 0;
    .headerContent {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  @include base.responsive {
    padding: 0;
    height: 80px;
  }

  &.headerAdmin {
    padding: 20px 40px 0;
    min-height: 80px;
    height: unset;

    .headerContent {
      background: #3f6a6e;
    }
  }

  .headerContent {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    //gap: 80px;
    background: #14343e;
    box-shadow: 0 10px 20px -10px rgba(20, 52, 62, 0.07);
    border-radius: 20px;

    @include base.responsive(1200px) {
      border-radius: 0;
    }

    .logo {
    }

    nav.nav {
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
      padding: 0;
      flex-grow: 1;
      align-self: stretch;
      min-height: 55px;
      gap: 4vw;
      margin: 0 20px;
    }

    .spacer {
      flex: 1;
    }
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .activeBorder {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    flex: 1;
    height: 10px;
    background: #65bc7b;
  }
}

.link {
  font-style: normal;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  word-break: keep-all;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.2s linear;

  &:not(.linkDisabled):hover {
    color: #65bc7b;
  }

  &.login {
    flex: 0;
  }

  &.linkDisabled {
    color: #888888;
    cursor: default;
  }
}
