@use "styles/base";

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: #e0f1f5;
}

.main {
  width: 100%;
  max-width: 1680px;
  padding: 80px 60px;
  flex: 1;
  gap: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @include base.responsive {
    padding: 40px 0;
  }

  //&.admin {
  //  padding: 0;
  //  flex-direction: column;
  //  flex-wrap: nowrap;
  //  align-items: stretch;
  //  justify-content: flex-start;
  //  gap: 0;
  //}
}

.footer {
  width: 100%;
  height: 68px;
  background: #14343e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 25px;
  padding: 0 10px;

  @include base.responsive {
    font-size: 0.7rem;
    line-height: 20px;
  }

  .version {
    margin-left: 0.5rem;
    font-size: 0.6rem;
  }
}
